import "./NeighborhoodAnalysis.css";
import NavBar from "../../components/nav-bar/NavBar";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import MapComponent from "../../components/map/Map";
import FrequentQuestions from "../../components/frequent-questions/FrequentQuestions";
import axios from "axios";
import PriceChart from "../../components/charts/LineChart";
import ContactUs from "../../components/contact-us/ContactUs";
import AboutEstimates from "../../components/about-estimates/AboutEstimates";
import { Link } from "react-router-dom";

function NeighborhoodAnalysis() {
  const price = useSelector((state) => state.price);
  const [data, setData] = React.useState({});
  const [neighborhood, setNeighborhood] = useState("");

  const [dataRent, setDataRent] = React.useState({});
  const [dataNew, setDataNew] = React.useState({});
  const [dataRentNew, setDataRentNew] = React.useState({});
  const [data1Bed, setData1Bed] = React.useState({});
  const [dataRent1Bed, setDataRent1Bed] = React.useState({});
  const [priceDataPoints, setPriceDataPoints] = React.useState([]);
  const [priceSqmDataPoints, setPriceSqmDataPoints] = React.useState([]);
  const [sqmDataPoints, setSqmDataPoints] = React.useState([]);
  const [googleCity, setGoogleCity] = useState("");
  const [googleNeighborhood, setGoogleNeighborhood] = useState("");
  const [extendQuest, setExtendQuest] = useState(true);
  const contactRef = useRef(null);
  const hasFetchedData = useRef(false);


  const scrollToElement = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  useEffect(() => {
    price.marker.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        setGoogleCity(component.long_name);
      } else if (component.types.includes("administrative_area_level_3")) {
        setGoogleNeighborhood(component.long_name);
      }
    });
  }, [price]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [data]);


  useEffect(() => {
    if (!hasFetchedData.current) {
      getNeighborhoodDataBuy();
      // getNeighborhoodDataRent();
      // getNeighborhoodDataBuy1Bed();
      // getNeighborhoodDataRent1Bed();
      // getNeighborhoodDataBuyNew();
      // getNeighborhoodDataRentNew();
      hasFetchedData.current = true;
    }
  }, []);

  const getNeighborhoodDataBuy = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_buy`,
        price.request
      )
      .then((res) => {
        if (res.data.foundNeighborhood) {
          setNeighborhood(res.data.neighborhood.split(",")[0]);
        }
        setData(res.data.data_buy);
        setDataRent(res.data.data_rent);
        setDataNew(res.data.data_buy_new);
        setDataRentNew(res.data.data_rent_new);
        setData1Bed(res.data.data_buy_1bed);
        setDataRent1Bed(res.data.data_rent_1bed);
       

      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataRent = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_rent`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setDataRent(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataBuyNew = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_buy_new`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setDataNew(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataRentNew = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_rent_new`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setDataRentNew(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataBuy1Bed = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_buy_1bed`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setData1Bed(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };
  const getNeighborhoodDataRent1Bed = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/get_neighborhood_analysis_rent_1bed`,
        price.request
      )
      .then((res) => {
        // alert.success("The property was updated");
        setDataRent1Bed(res.data);
      })

      .catch((err) => {
        // if (err.response?.status !== 500) {
        //   if (err.response) {
        //     Object.keys(err.response.data).forEach(function (key, index) {
        //       alert.error(key + ":" + err.response.data[key]);
        //     });
        //   } else {
        //     alert.error(err.message);
        //   }
        // }

        console.log(err);
      });
  };

  return (
    <div className="na-main ">
      <NavBar />
      {data.price && (
        <div className="rn-column-start-start">
          <span className="font-gothic-b28 font-blue">
            {" "}
            {googleCity}
            {googleNeighborhood !== "" && ","}{" "}
            {neighborhood !== "" ? neighborhood : googleNeighborhood}
          </span>
          <div className="line-grey">
            <span className="font-gothic-b18 font-blue">
              Πληροφορίες περιοχής
            </span>
            <KeyboardArrowDownRoundedIcon className="blue" />
          </div>

          <div style={{ width: "100%" }}>
            {console.log(price.marker)}
            <MapComponent
              style={{ border: "1px solid black", width: "100%" }}
              marker={price.marker}
              height={"233px"}
            />
          </div>
          <div className="rp-green-img">
            <div className="green-column">
              <span className="font-gothic-b32 white">
                Πολλοί Διαθέσιμοι αγοραστές
              </span>
              <span className="font-inter18 white">
                ζητούν ακίνητα στην περιοχή
              </span>
            </div>
            <button
              onClick={scrollToElement}
              className="button-outline-white  font-gothic-b16"
            >
              Θέλω να το πουλήσω!
            </button>
          </div>

          <span className="font-gothic-b28 font-blue margin-t5p">
            Γενικοί δείκτες τιμών ακινήτων newdeal {neighborhood}
          </span>

          <span className="font-inter18 font-blue">
            Αυτή η εκτίμηση βασίζεται σε προηγμένες μεθόδους τεχνητής νοημοσύνης
            που, στις περισσότερες περιπτώσεις, καταλήγουν σε υψηλή ακρίβεια.
            Ωστόσο, οι εκτιμήσεις μπορεί να χρήζουν προσαρμογής ανάλογα με τα
            ακριβή χαρακτηριστικά του ακίνητου σας. Μη διστάσετε να
            προγραμματίσετε ένα δωρεάν ραντεβού με έναν Σύμβουλο της newdeal
            ώστε να συζητήσουμε για το ακίνητό σας σε περισσότερη λεπτομέρεια.
          </span>

          <div className="stats-row margin-t5p">
            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-buy.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b22 font-blue">Τιμή Πώλησης</span>
                <HelpRoundedIcon
                  style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                />
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={data.data ? data.data : []}
                  transactionType={"BUY"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b14 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(data.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(data.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(data.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>

            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-rent.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b22 font-blue">
                  Τιμή Ενοικίασης
                </span>
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={dataRent.data ? dataRent.data : []}
                  transactionType={"RENT"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b14 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRent?.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(dataRent?.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRent?.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent?.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent?.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent?.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>
          </div>

          <span className="font-gothic-b28 font-blue margin-t5p">
            Νεόδμητα διαμερίσματα
          </span>

          <span className="font-inter18 font-blue">
            Αυτή η εκτίμηση βασίζεται σε προηγμένες μεθόδους τεχνητής νοημοσύνης
            που, στις περισσότερες περιπτώσεις, καταλήγουν σε υψηλή ακρίβεια.
            Ωστόσο, οι εκτιμήσεις μπορεί να χρήζουν προσαρμογής ανάλογα με τα
            ακριβή χαρακτηριστικά του ακίνητου σας. Μη διστάσετε να
            προγραμματίσετε ένα δωρεάν ραντεβού με έναν Σύμβουλο της newdeal
            ώστε να συζητήσουμε για το ακίνητό σας σε περισσότερη λεπτομέρεια.
          </span>

          <div className="stats-row margin-t5p">
            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-buy.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b22 font-blue">Τιμή Πώλησης</span>
                <HelpRoundedIcon
                  style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                />
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={dataNew.data ? dataNew.data : []}
                  transactionType={"Buy"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b12 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataNew?.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(dataNew?.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataNew?.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataNew?.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataNew?.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataNew?.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>

            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-rent.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b22 font-blue">
                  Τιμή Ενοικίασης
                </span>
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={dataRentNew.data ? dataRentNew.data : []}
                  transactionType={"RENT"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b12 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRentNew.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(dataRentNew?.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRentNew?.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRentNew.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRentNew.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRentNew.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>
          </div>

          <span className="font-gothic-b28 font-blue margin-t5p">
            Παλαιότερα διαμερίσματα με έως 1 υπνοδωμάτιο
          </span>

          <span className="font-inter18 font-blue">
            Αυτή η εκτίμηση βασίζεται σε προηγμένες μεθόδους τεχνητής νοημοσύνης
            που, στις περισσότερες περιπτώσεις, καταλήγουν σε υψηλή ακρίβεια.
            Ωστόσο, οι εκτιμήσεις μπορεί να χρήζουν προσαρμογής ανάλογα με τα
            ακριβή χαρακτηριστικά του ακίνητου σας. Μη διστάσετε να
            προγραμματίσετε ένα δωρεάν ραντεβού με έναν Σύμβουλο της newdeal
            ώστε να συζητήσουμε για το ακίνητό σας σε περισσότερη λεπτομέρεια.
          </span>
          <div className="stats-row margin-t5p">
            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-buy.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b18 font-blue">Τιμή Πώλησης</span>
                <HelpRoundedIcon
                  style={{ fontSize: 15, color: "grey", marginLeft: 10 }}
                />
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={data1Bed.data ? data1Bed.data : []}
                  transactionType={"BUY"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b12 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(data1Bed.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(data1Bed.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(data1Bed.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data1Bed.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data1Bed.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(data1Bed.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>
            <div className="stats-card">
              <div className="stats-card-top">
                <img
                  style={{ marginRight: 15 }}
                  src={require("../../assets/stats-icon-rent.png")}
                  alt="developing vector"
                />
                <span className="font-gothic-b18 font-blue">
                  Τιμή Ενοικίασης
                </span>
              </div>
              <div className="stats-card-middle">
                <PriceChart
                  data={dataRent1Bed.data ? dataRent1Bed.data : []}
                  transactionType={"RENT"}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="stats-card-bottom">
                <div className="row-space-between-center width100p">
                  <span className="font-inter12 font-blue">Ελάχιστη τιμή</span>
                  <span className="font-inter-b12 red">Μέση τιμή</span>
                  <span className="font-inter12 font-blue">Μέγιστη</span>
                </div>

                <div className="row-space-between-center width100p">
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRent1Bed.price?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="na-font-gothic-b34-20 font-blue">
                    {parseFloat(
                      parseFloat(dataRent1Bed.price?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                  <div className="stats-bottom-line1"></div>
                  <span className="font-inter-b18 font-blue">
                    {parseFloat(
                      parseFloat(dataRent1Bed.price?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €
                  </span>
                </div>

                <div className="row-space-between-center width100p">
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent1Bed.price_per_sqm?.min).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-b18 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent1Bed.price_per_sqm?.price).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                  <div className="stats-bottom-line2"></div>
                  <span
                    style={{ opacity: 0.5 }}
                    className="font-inter-semi16 font-blue"
                  >
                    {parseFloat(
                      parseFloat(dataRent1Bed.price_per_sqm?.max).toFixed(0)
                    ).toLocaleString()}{" "}
                    €/τμ
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {window.innerWidth >= 1200 ? (
        <div className="six-icons-back">
          <div className="six-icons-front">
            <div className="row-space-between-center">
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/1of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Αποκλεɩστɩκή πρόσβαση σε επενδυτές εξωτερɩκού
                </span>
              </div>
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/2of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Έχουμε τους καλύτερους Συμβούλους Ακɩνήτων
                </span>
              </div>
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/3of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Τεχνολογία Αɩχμής
                </span>
              </div>
            </div>

            <div className="row-space-between-center margin-t5p">
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/4of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Πρωτοπορɩακή προώθηση Ακɩνήτων
                </span>
              </div>
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/5of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Premium Υπηρεσίες στο μέγɩστο επίπεδο
                </span>
              </div>
              <div className="one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/6of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24 pale-blue">
                  Εκτεταμένη Εμπεɩρία
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="six-icons-back">
          <div className="mob-six-icons-front">
            <div className="row-space-between-center margin-t40">
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/1of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Αποκλεɩστɩκή πρόσβαση σε επενδυτές εξωτερɩκού
                </span>
              </div>
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/2of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Έχουμε τους καλύτερους Συμβούλους Ακɩνήτων
                </span>
              </div>
            </div>

            <div className="row-space-between-center margin-t40">
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/3of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Τεχνολογία Αɩχμής
                </span>
              </div>
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/4of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Πρωτοπορɩακή προώθηση Ακɩνήτων
                </span>
              </div>
            </div>

            <div className="row-space-between-center margin-t40">
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/5of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Premium Υπηρεσίες στο μέγɩστο επίπεδο
                </span>
              </div>
              <div className="mob-one-o6-column">
                <img
                  className="one-of6-icon"
                  src={require("../../assets/6of6.png")}
                  alt="developing vector"
                />
                <span className="font-inter-semi24-media pale-blue">
                  Εκτεταμένη Εμπεɩρία
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {window.innerWidth >= 500 ? (
        <div className="blue-map-small">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="column-k">
              <span className="font-gothic-b79 white">250+</span>
              <span className="font-inter-semi24 white">Συνεργάτες</span>
            </div>
            <div className="column-k">
              <span className="font-gothic-b79 white">30k+</span>
              <span className="font-inter-semi24 white">Ζητήσεɩς Ακɩνήτων</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="column-k">
              <span className="font-gothic-b79 white">10k+</span>
              <span className="font-inter-semi24 white">Δɩαθέσɩμα Ακίνητα</span>
            </div>
            <div className="column-k">
              <span className="font-gothic-b79 white">18+</span>
              <span className="font-inter-semi24 white">Χρόνɩα Εμπεɩρίας</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mob-blue-map-small ">
          <div className="column-k">
            <span className="font-gothic-b79 white">250+</span>
            <span className="font-inter-semi24 white">Συνεργάτες</span>
          </div>
          <div className="column-k">
            <span className="font-gothic-b79 white">30k+</span>
            <span className="font-inter-semi24 white">Ζητήσεɩς Ακɩνήτων</span>
          </div>
          <div className="column-k">
            <span className="font-gothic-b79 white">10k+</span>
            <span className="font-inter-semi24 white">Δɩαθέσɩμα Ακίνητα</span>
          </div>
          <div className="column-k">
            <span className="font-gothic-b79 white">18+</span>
            <span className="font-inter-semi24 white">Χρόνɩα Εμπεɩρίας</span>
          </div>
        </div>
      )}

      {data.price && (
        <div className="rn-column-middle">
          <div className="cooperate-back">
            <div className="na-cooperate-front text-center">
              <span className="font-gothic-b40 red margin-t5p  margin-b2p">
                Γιατί να συνεργαστείς μαζί μας;
              </span>
              <div className="line-yellow margin-b2p"></div>
              <span className="font-inter24 font-light-grey">
                Η εφαμορφή
                <span className="font-inter-b24 font-light-grey">
                  {""} "Price my Property" (PMP) {""}
                </span>
                καɩ η συνεργασία μας με το
                <span className="font-inter-b24 font-light-grey">
                  {""} ΕΚΠΑ {""}
                </span>
                είναι μόνο ένα από τα εργαλεία και τις υπηρεσίες που παρέχει η
                <span className="font-inter-b24 font-light-grey">
                  {""} newdeal real estate group, {""}
                </span>
                καɩ έχεɩς πολλούς λόγους να συνεργαστείς μαζί μας:
              </span>
            </div>
          </div>

          <div className="we-give">
            <span className="font-inter24 font-light-grey">
              Δίνουμε
              <span className="font-inter-b24 font-light-grey">
                {""} μεγαλύτερη αξία {""}
              </span>
              στην ακίνητη περιουσία σας από έναν παραδοσιακό μεσίτη.
            </span>
            <Link to="https://newdeal.gr/el/add-listing">
              <button className="button-red-no-width font-inter-semi16 white margin-t5p">
                Μάθε περɩσσότερα
              </button>
            </Link>
          </div>
        </div>
      )}

      {/* <div className="na2-column-start-start">
        <div className="nearby-back">
          <div className="row-start-center margin-b40">
            <img
              style={{ marginRight: 15 }}
              src={require("../../assets/stats-icon-buy.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b24 red">
              Ακίνητα που πωλούνται κοντά
            </span>
            <img
              style={{ height: 25, marginRight: 10, marginLeft: 40 }}
              src={require("../../assets/map-icon.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b16 font-blue underline-thin">
              Δες στον χάρτη
            </span>
          </div>

          <div className="na-exclusive-row">
            <div className="na-exclusive-card">
              <div className="na-exclusive-img">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Ενοɩκίαση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button font-inter-m14 font-blue">
                    ΜΟΥ5674
                  </button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 5, width: 32 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className=" font-gothic-b18 font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span className="font-inter-semi18 dark-grey margin-t20">
                  Μονοκατοɩκία, 850 τ.μ.
                </span>
                <span className="font-inter-m14 dark-grey">
                  Μεγάλη Μαντίνεɩα, Αβία, ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία,
                  ΜεσσηνίαΜεγάλη Μαντίνεɩα, Αβία, Μεσσηνία Με 2 αποθήκες | Με
                  θέσεɩς πάρκɩνγκ
                </span>
                <div className="row-start-center">
                  <img
                    style={{ marginRight: 10, width: 21 }}
                    src={require("../../assets/business.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi14 dark-grey">
                    2ος Όρoφος
                  </span>
                  <img
                    style={{ marginRight: 10, width: 22, marginLeft: 20 }}
                    src={require("../../assets/couch.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi14 dark-grey">6</span>

                  <img
                    style={{ marginRight: 10, width: 24, marginLeft: 20 }}
                    src={require("../../assets/bathtub.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi14 dark-grey">8</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span className="font-inter-semi24 font-blue">
                      1.590.000 €
                    </span>
                    <span
                      className="font-inter-semi16"
                      style={{ opacity: 0.6, color: "#263573", marginLeft: 20 }}
                    >
                      5.800 €/τμ
                    </span>
                  </div>
                  <img
                    style={{ width: 22 }}
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>

            <div className="na-exclusive-card">
              <div className="na-exclusive-img-land">
                <div className="na-icon-row">
                  <div className="vip-icon">
                    <span className="white">VIP</span>
                  </div>
                  <div className="rental-icon">
                    <span className="white">Πώληση</span>
                  </div>
                </div>
                <div className="na-moy-row">
                  <button className="moy-button">ΜΟΥ5674</button>

                  <button className="more-pictures-button">
                    <img
                      style={{ marginRight: 25 }}
                      src={require("../../assets/more-pictures.png")}
                      alt="developing vector"
                    />
                    <span className="font-blue">18</span>
                  </button>
                </div>
              </div>

              <div className="na-exclusive-bottom">
                <span className="font-inter-semi18 dark-grey margin-t20">
                  Οɩκόπεδο, 2500 τ.μ.
                </span>
                <span className="font-inter-m14 dark-grey">
                  Μεγάλη Μαντίνεɩα, Αβία, Μεσσηνία
                </span>
                <div className="row-start-center">
                  <img
                    style={{ width: 22, marginRight: 10 }}
                    src={require("../../assets/land-icon1.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi14 dark-grey">
                    Εντός Σχεδίου
                  </span>
                  <img
                    style={{ width: 22, marginRight: 10, marginLeft: 20 }}
                    src={require("../../assets/land-icon2.png")}
                    alt="developing vector"
                  />
                  <span className="font-inter-semi14 dark-grey">Σ.Δ. 0,80</span>
                </div>

                <div className="exclusive-card-border" />
                <div className="row-space-between-center width100p">
                  <div className="row-start-center">
                    <span className="font-inter-semi24 font-blue">
                      1.590.000 €
                    </span>
                    <span
                      className="font-inter-semi16"
                      style={{ opacity: 0.6, color: "#263573", marginLeft: 20 }}
                    >
                      5.800 €/τμ
                    </span>
                  </div>
                  <img
                    style={{ width: 22 }}
                    src={require("../../assets/heart-filled.png")}
                    alt="developing vector"
                  />
                </div>
              </div>
            </div>
          </div>
          <span
            className="font-inter16 font-blue underline-2 margin-t20"
            style={{ alignSelf: "end" }}
          >
            Δες όλα τα ακίνητα που πωλούνται κοντά στην περιοχή
          </span>
        </div>

        <div className="na-row-icon-pad">
          <div className="row-start-center">
            <img
              style={{ width: 42, marginRight: 15 }}
              src={require("../../assets/house.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b18 font-blue">
              Κατοικίες για πώληση <br />
              Κολωνάκι
            </span>
          </div>

          <div className="row-start-center">
            <img
              style={{ width: 42, marginRight: 15 }}
              src={require("../../assets/skyscraper.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b18 font-blue">
              Επαγγελματικές στέγες <br /> για πώληση Κολωνάκι
            </span>
          </div>

          <div className="row-start-center">
            <img
              style={{ width: 42, marginRight: 15 }}
              src={require("../../assets/blueprint.png")}
              alt="developing vector"
            />
            <span className="font-gothic-b18 font-blue">
              Γη για πώληση <br /> Κολωνάκι
            </span>
          </div>
        </div>
      </div> */}
      <div className="na-frequent-questions">
        <FrequentQuestions />
      </div>

      <div ref={contactRef}>
        <ContactUs />
      </div>

      <AboutEstimates />
    </div>
  );
}

export default NeighborhoodAnalysis;
